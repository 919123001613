import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TruckService} from '../../common/truck.service';
import {MatSnackBar} from '@angular/material';
import {DateValidator} from '../../common/date-validation';
import {YearValidator} from '../../common/year-validation';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-truck',
    templateUrl: './edit-truck.component.html',
    styleUrls: ['./edit-truck.component.scss']
})
export class EditTruckComponent implements OnInit {

    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading: boolean = true;
    public materials = [];
    public submitted = false;

    constructor(private formBuilder: FormBuilder,
                private truckService: TruckService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            truck_number: ['', Validators.required],
            year: [null, YearValidator()],
            make: null,
            model: null,
            vin: null,
            tire_size: null,
            license_plate: null,
            lienholder: null,
            miles: null,
            engine_size: null,
            transmission: null,
            notes: null,
            active: true,
            last_service_date: [null, DateValidator('MM/dd/YYYY') ],
            last_annual_inspection: [null, DateValidator('MM/dd/YYYY') ]
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }
    }

    find(id: number) {
        this.truckService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.form.patchValue({
                truck_number: response.truck_number,
                year: response.year,
                make: response.make,
                model: response.model,
                vin: response.vin,
                tire_size: response.tire_size,
                license_plate: response.license_plate,
                lienholder: response.lienholder,
                miles: response.miles,
                engine_size: response.engine_size,
                transmission: response.transmission,
                notes: response.notes,
                active: response.active == 1 ? true : false,
                last_service_date: response.last_service_date !== null ? new Date(new Date(response.last_service_date).getTime() + new Date().getTimezoneOffset() * 60 * 1000) : null,
                last_annual_inspection: response.last_annual_inspection !== null ? new Date(new Date(response.last_annual_inspection).getTime() + new Date().getTimezoneOffset() * 60 * 1000) : null
            });
        });
    }

    onSubmit() {
        this.submitted = true;

        if ( ! this.form.valid) {
            return;
        }

        this.loading = true;

        let payload = this.form.value;

        Object.keys(payload).forEach((key) => (payload[key] == null) && delete payload[key]);

        if (payload.last_service_date) {
            payload.last_service_date = payload.last_service_date.toLocaleDateString('en-us');
        }

        if (payload.last_annual_inspection) {
            payload.last_annual_inspection = payload.last_annual_inspection.toLocaleDateString('en-us');
        }

        this.truckService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.snackbar.open('Truck saved successfully', 'Close', {
                duration: 3000
            });
            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        });
    }

    getFormValidationErrors() {
        Object.keys(this.form.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }
}
