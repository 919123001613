<mat-form-field class="main-body" (click)="focus()">
  <mat-select #main_select
      [multiple]="multiple"
      [placeholder]="placeholder"
      [disableOptionCentering]="true"
      (selectionChange)="makeSelection($event)"
      [(value)]="value">
    <div *ngIf="!readOnly" class="input-line">
      <input class="input" matInput
            autocomplete="off"
            id="search_select_filter"
            name="trailer_year"
            placeholder="Search"
            [formControl]="inputControl"
            (keydown)="_handleKeydown($event)"/> 
      <a (click)="clearControl()"><mat-icon matTooltip="Clear Selection" matTooltipShowDelay="500">clear</mat-icon></a>
    </div>
    <div *ngIf="isLoading && !readOnly" class="is-loading mat-option">
      <mat-spinner diameter="40"></mat-spinner>
    </div>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let entry of list" [value]="entry" [disabled]="readOnly && multiple">
        <span>{{ formatFieldName(entry) }}</span>
        <small> | ID: {{entry?.id}}</small>
      </mat-option>
    </ng-container>
    <ng-container *ngIf="list?.length == 0 && isLoading == false">
      <div class="mat-option">
        <span>no {{placeholder}}(s) found...</span>
      </div>
    </ng-container>
  </mat-select>
</mat-form-field>
