import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatMenuTrigger, MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {NotificationService} from "../../../common/notification.service";
import {ApiService} from "../../../common/api.service";

@Component({
    selector: 'app-notification-dropdown',
    templateUrl: './notification-dropdown.component.html',
    styleUrls: ['./notification-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationDropdownComponent implements OnInit {

    public notifications = [];
    public notificationQueue = [];

    public unread = 0;
    @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger;

    constructor(private notificationService: NotificationService,
                private apiService: ApiService,
                private router: Router,
                private snackbar: MatSnackBar) {
    }

    ngOnInit() {
        this.notificationService.unreadNotifications.subscribe((response: any) => {
            if(this.notifications.length < 8) {
                this.notifications.push(response);
            } else {
                this.notificationQueue.push(response);
            }


            if (!response.read) {
                this.unread++;
            }
        });

        this.notificationService.onMarkAllAsRead().subscribe((event: any) => {
            if(event.from === 'notification-dropdown') {
                return;
            }

            this.notifications = [];
            this.notificationQueue = [];
            this.unread = 0;
        });

        // TODO: Refactor
        this.notificationService.onMarkAsRead().subscribe((event: any) => {

            if(event.from === 'notification-dropdown') {
                return;
            }

            let notifications = this.notifications.filter(notification => {
                return notification.id === event.id;
            });

            if(notifications.length > 0) {
                const index = this.notifications.indexOf(notifications[0]);
                this.notifications.splice(index, 1);
                this.unread -= 1;
                this.fillNotifications();
            } else {
                let notifications = this.notificationQueue.filter(notification => {
                    return notification.id === event.id;
                });

                if(notifications.length > 0) {
                    const index = this.notificationQueue.indexOf(notifications[0]);
                    this.notificationQueue.splice(index, 1);
                    this.unread -= 1;
                    this.fillNotifications();
                }
            }
        });

        this.notificationService.fetchUnread();
    }

    ngAfterViewInit(): void {
        this.ddTrigger.menuOpened.subscribe(() => {});
    }

    value: string;
    selectedValue: string;

    cancelClick(ev: MouseEvent) {
        ev.stopPropagation();
    }

    onCancel() {
        this.value = undefined;
        this.ddTrigger.closeMenu();
    }

    onNotificationClick(notification) {
        if(notification.data.job_id) {
            this.router.navigate(['/dispatch/jobs/', notification.data.job_id]);
            this.toggleRead(null, notification);
            return;
        }
    }

    toggleRead(event = null, notification) {
        if(event !== null) {
            event.stopPropagation();
        }

        notification.data.read = !notification.data.read;

        this.unread -= 1;

        const index = this.notifications.indexOf(notification);

        this.notificationService.markAsRead(notification, 'notification-dropdown').subscribe(response => {
            this.notifications.splice(index, 1);

            if(this.notifications.length < 8) {
                this.fillNotifications();
            }
        });
    }

    public markAllAsRead() {
        this.notificationService.markAllAsRead('notification-dropdown').subscribe(response => {
            this.unread = 0;
            this.notifications = [];
            this.notificationQueue = [];
        });
    }

    private fillNotifications() {
        if(this.notifications.length < 8) {
            if(this.notificationQueue.length > 0) {
                this.notifications.push(this.notificationQueue.shift());
                this.fillNotifications();
            }
        }
    }
}
