import { Component, OnInit } from '@angular/core';
import {UserService} from '../../common/user.service';
import {CertExpirationService} from '../../common/cert-expiration.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SearchableEndpoint } from '../search-select/search-select.component';
import { MatSnackBar } from '@angular/material';

export interface User {
  id: number;
  first_name: string;
  last_name: string;
}


@Component({
  selector: 'app-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss']
})
export class CertificationComponent implements OnInit {

  public form: FormGroup;
  public lastPage;
  public currentPage;
  public certNotifications = [];
  public total;

  constructor(private userService: UserService,
              private certExpirationService: CertExpirationService,
              private formBuilder: FormBuilder,
              private snackbar: MatSnackBar
              ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      user: [null, Validators.required],
    });

    this.fetchCertExpirations();
  }

  fetchCertExpirations(page = 1) {
    this.certExpirationService.find(page).subscribe((response) => {
      this.lastPage = response.last_page;
      this.currentPage = response.current_page;
      this.certNotifications = response.data;
      this.total = response.total;

  });
  }

  paginate(event) {
    this.fetchCertExpirations(event);
  }

  getEndpointUser(): SearchableEndpoint {
    return ((search: String, page = 1) => {
        return this.userService.find(page, 25, {search: search});
    }).bind(this);
  }

  formatFieldName(user) {
    return user.first_name + ' ' + user.last_name;
  }

  addUser() {
    const user = this.form.get('user').value as User;
    if (user) {
      // makes first check for duplicates
      for (let i = 0; i < this.certNotifications.length; i++) {
        let currentUser = this.certNotifications[i].user;
        if (currentUser.id === user.id) {
          this.snackbar.open('This user is already in the Certification Notifications', 'Close', {
            duration: 5000
          });
          this.form.get('user').setValue(null);
          return;
        }
      }
      this.certExpirationService.save({user_id: user.id}).subscribe((response) => {
        if (this.currentPage === this.lastPage && this.certNotifications.length < 5) {
          this.certNotifications.push({id: response.id, user: {id: user.id, first_name: user.first_name, last_name: user.last_name}});
        }
        this.total++;
      }, (error) => {
        if (error.message.includes('422')) {
          this.snackbar.open('This user is already in the Certification Notifications', 'Close', {
            duration: 5000
        });
        }
      }
      );
    }
    this.form.get('user').setValue(null);
  }

  remove(certNotification) {
    this.certExpirationService.delete(certNotification.id).subscribe(() => {
      for (let i = 0; i < this.certNotifications.length; i++) {
        let currentUser = this.certNotifications[i].user;
        if (currentUser.id === certNotification.user.id) {
          this.certNotifications.splice(i, 1);
        }
      }
      if (this.certNotifications.length > 0) {
        this.fetchCertExpirations(this.currentPage);
      } else {
        this.fetchCertExpirations(1);
      }
    });
  }

}
