import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

@Injectable({
    providedIn: 'root'
})
export class KeepTruckinUserService {

    constructor(private apiService: ApiService) {
    }

    public search(pageNumber = 1, limit = 25, params = {}) {
        let options = {
            params: {
                ...params,
                page: pageNumber.toString(),
                limit: limit.toString(),
        }};

        return this.apiService.get('keep-truckin/users', options);
    }

    public truckLocation(truck) {
        return this.apiService.get(`keep-truckin/linked-trucks/${truck}/location`);
    }

    public truckLocations(truckIds = null, page = 1, limit = 25): any {
        let params = {
            page: page,
            limit: limit
        };

        if (truckIds) {
            params['keep_truckin_ids'] = truckIds;
        }

        return this.apiService.get('keep-truckin/linked-trucks/locations', { params: params });
    }

    public availableTime(driverIds = [], page = 1, limit = 25) {
        return this.apiService.get('keep-truckin/available-time', {
            params: {
                driver_ids: driverIds,
                page: page,
                limit: limit,
            }
        });
    }

    public driverLocations(driverIds = [], page = 1, limit = 25) {
        return this.apiService.get('keep-truckin/driver-locations', {
            params: {
                driver_ids: driverIds,
                page: page,
                limit: limit,
            }
        });
    }

    public getVehicles() {
        //
    }


}
