<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative;">
    <ngx-loading [show]="loading"></ngx-loading>
    <h1 *ngIf="mode === 'edit'">Edit Rig</h1>
    <h1 *ngIf="mode === 'new'">New Rig</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-checkbox (onChange)="getEndpoint()" *ngIf="mode === 'new'" formControlName='is_otr' color="primary" class="otr-checkbox">Is OTR</mat-checkbox>
        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input name="rig_name" matInput placeholder="Name" required [formControlName]="'name'">
            <mat-error *ngIf="form.get('name').hasError('required') && submitted">
                Rig name is required.
            </mat-error>
        </mat-form-field>

        <app-search-select class="search-select" formControlName="customer" [endpoint]="getEndpoint()" [placeholder]="'Customer'" [shouldRefresh]="this.shouldRefresh" ></app-search-select>

        <button mat-stroked-button [disabled]="loading" style="margin-top: 20px;">
            Save
        </button>
    </form>

</div>
