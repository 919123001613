import {AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
import {MaterialsTableDataSource} from './materials-table-datasource';
import {MaterialService} from "../../../common/material.service";
import {tap} from "rxjs/operators";
import {AreYouSureDialogComponent} from "../../ui/are-you-sure-dialog/are-you-sure-dialog.component";

@Component({
    selector: 'app-materials-table',
    templateUrl: './materials-table.component.html',
    styleUrls: ['./materials-table.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MaterialsTableComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dataSource: MaterialsTableDataSource;
    pageCount = 0;
    otr = false;
    filters = null;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns = ['name', 'actions'];

    constructor(private materialService: MaterialService,
                private snackbar: MatSnackBar,
                private dialog: MatDialog) {

    }

    ngOnInit() {
        this.dataSource = new MaterialsTableDataSource(this.materialService);
        this.dataSource.loadMaterials(1, 25);
    }

    ngAfterViewInit(): void {
        this.paginator.page
            .pipe(
                tap(() => this.loadPage())
            )
            .subscribe();
    }

    loadPage() {
        this.dataSource.loadMaterials(this.paginator.pageIndex + 1, this.paginator.pageSize, this.filters);
    }

    onDelete(row) {
        const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
            width: '600px',
            data: { id: row.id, service: this.materialService }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result && result.deleted) {
                this.loadPage();
                this.snackbar.open("Material deleted successfully", "Close", {
                    duration: 3000
                });
            }
        });
    }

    toggleOtr(e) {
        this.otr = e.checked
        this.filters = this.otr ? {otr: true} : null
        this.loadPage()
    }
}
