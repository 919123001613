import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DispatchComponent } from './dispatch.component';

import { dispatchRoutes } from './dispatch.routing';
import { RouterModule } from '@angular/router';
import {
    MatAutocompleteModule, MatBadgeModule,
    MatButtonModule, MatCardModule,
    MatDatepickerModule,
    MatDialogModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule, MatListModule,
    MatMenuModule, MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule,
    MatSortModule,
    MatTableModule, MatTabsModule,
    MatToolbarModule,
    MatCheckboxModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UiModule } from './ui/ui.module';
import { TrucksComponent } from './trucks/trucks.component';
import { TrailersComponent } from './trailers/trailers.component';
import { UsersComponent } from './users/users.component';
import { MaterialsComponent } from './materials/materials.component';
import { MaterialsTableComponent } from './materials/materials-table/materials-table.component';
import { EditMaterialComponent } from './edit-material/edit-material.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { BillingTypesComponent } from './billing-types/billing-types.component';
import { BillingTypesTableComponent } from './billing-types/billing-types-table/billing-types-table.component';
import { EditBillingTypeComponent } from './edit-billing-type/edit-billing-type.component';
import { CustomersComponent } from './customers/customers.component';
import { RigsComponent } from './rigs/rigs.component';
import { WellsComponent } from './wells/wells.component';
import { EditCustomerComponent } from './edit-customer/edit-customer.component';
import { EditRigComponent } from './edit-rig/edit-rig.component';
import { EditWellComponent } from './edit-well/edit-well.component';
import { EditTruckComponent } from './edit-truck/edit-truck.component';
import { EditTrailerComponent } from './edit-trailer/edit-trailer.component';
import { CustomersTableComponent } from './customers/customers-table/customers-table.component';
import { RigsTableComponent } from './rigs/rigs-table/rigs-table.component';
import { WellsTableComponent } from './wells/wells-table/wells-table.component';
import { TrucksTableComponent } from './trucks/trucks-table/trucks-table.component';
import { UsersTableComponent } from './users/users-table/users-table.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { TrailerTypesComponent } from './trailer-types/trailer-types.component';
import { EditTrailerTypeComponent } from './edit-trailer-type/edit-trailer-type.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { EditTrainingComponent } from './edit-training/edit-training.component';
import { CertificationComponent } from './certification/certification.component';
import { EditCertificationComponent } from './edit-certification/edit-certification.component';
import { TrailerTypesTableComponent } from './trailer-types/trailer-types-table/trailer-types-table.component';
import { CertificationsTableComponent } from './certification/certifications-table/certifications-table.component';
import { TrainingsTableComponent } from './trainings/trainings-table/trainings-table.component';
import {
    UserCertificationsComponent
} from './edit-user/certifications/certifications.component';
import { EditCertificationDialogComponent } from './edit-user/edit-certification-dialog/edit-certification-dialog.component';
import { EditTrainingDialogComponent } from './edit-user/edit-training-dialog/edit-training-dialog.component';
import { UserTrainingsComponent } from './edit-user/trainings/trainings.component';
import { TrailersTableComponent } from './trailers/trailers-table/trailers-table.component';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { KeepTruckinDialogComponent } from './edit-user/keep-truckin-dialog/keep-truckin-dialog.component';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from './profile/change-password/change-password.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NotificationsComponent } from './notifications/notifications.component';
import { AvatarComponent } from './edit-user/avatar/avatar.component';
import { EditLocationTypeComponent } from './edit-location-type/edit-location-type.component';
import { EditLocationComponent } from './edit-location/edit-location.component';
import { LocationsComponent } from './locations/locations.component';
import { LocationTypesComponent } from './location-types/location-types.component';
import { LocationTypesTableComponent } from './location-types/location-types-table/location-types-table.component';
import { LocationsTableComponent } from './locations/locations-table/locations-table.component';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule} from '@agm/core';
import { environment } from '../../environments/environment';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TruckSnapshotBoardComponent } from './truck-snapshot-board/truck-snapshot-board.component';
import { ReviewEditComponent } from './jobs/edit/bol-jsa/review-edit/review-edit.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ViewUsersCertificationComponent } from './certification/view-users-certification/view-users-certification.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompaniesTableComponent } from './companies/companies-table/companies-table.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { AgmDirectionModule } from 'agm-direction';

@NgModule({
    declarations: [
        DispatchComponent,
        TrucksComponent,
        TrailersComponent,
        UsersComponent,
        MaterialsComponent,
        MaterialsTableComponent,
        EditMaterialComponent,
        BillingTypesComponent,
        BillingTypesTableComponent,
        EditBillingTypeComponent,
        CustomersComponent,
        RigsComponent,
        WellsComponent,
        EditCustomerComponent,
        EditRigComponent,
        EditWellComponent,
        EditTruckComponent,
        EditTrailerComponent,
        CustomersTableComponent,
        RigsTableComponent,
        WellsTableComponent,
        TrucksTableComponent,
        UsersTableComponent,
        EditUserComponent,
        TrailerTypesComponent,
        EditTrailerTypeComponent,
        TrainingsComponent,
        EditTrainingComponent,
        CertificationComponent,
        EditCertificationComponent,
        TrailerTypesTableComponent,
        CertificationsTableComponent,
        TrainingsTableComponent,
        UserCertificationsComponent,
        UserTrainingsComponent,
        EditCertificationDialogComponent,
        EditTrainingDialogComponent,
        TrailersTableComponent,
        KeepTruckinDialogComponent,
        ProfileComponent,
        AvatarComponent,
        ChangePasswordComponent,
        NotificationsComponent,
        EditLocationTypeComponent,
        EditLocationComponent,
        LocationsComponent,
        LocationTypesComponent,
        LocationTypesTableComponent,
        LocationsTableComponent,
        TruckSnapshotBoardComponent,
        ReviewEditComponent,
        ViewUsersCertificationComponent,
        CompaniesComponent,
        CompaniesTableComponent,
        EditCompanyComponent,
    ],
    imports: [
        CommonModule,
        MatExpansionModule,
        MatTableModule,
        MatCardModule,
        MatIconModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatMenuModule,
        MatToolbarModule,
        MatButtonModule,
        MatDialogModule,
        MatSliderModule,
        MatListModule,
        MatSlideToggleModule,
        ImageCropperModule,
        MatTabsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatSelectModule,
        FlexLayoutModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        UiModule,
        LoadingBarHttpClientModule,
        MatSnackBarModule,
        AgmCoreModule.forRoot({
            apiKey: environment.google_maps_key,
            libraries: ['places']
        }),
        AgmDirectionModule,
        MatGoogleMapsAutocompleteModule.forRoot(),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            primaryColour: '#ffffff',
            secondaryColour: '#ccc'
        }),
        RouterModule.forChild(dispatchRoutes),
        NgxMatSelectSearchModule,
        FormsModule,
        ScrollingModule
    ],
    entryComponents: [
        EditCertificationDialogComponent,
        EditTrainingDialogComponent,
        KeepTruckinDialogComponent,
        ReviewEditComponent,
        ViewUsersCertificationComponent
    ],
})
export class DispatchModule {
}
