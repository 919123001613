<div class="materials-edit mat-elevation-z2 wrapper" style="position: relative">

    <ngx-loading [show]="loading"></ngx-loading>

    <h1 *ngIf="mode === 'edit'">Edit User</h1>
    <h1 *ngIf="mode === 'new'">New User</h1>

    <form class="materials-edit-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input matInput placeholder="First Name" required [formControlName]="'first_name'">
            <mat-error *ngIf="form.get('first_name').hasError('required') && submitted">
                First name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input matInput placeholder="Last Name" required [formControlName]="'last_name'">
            <mat-error *ngIf="form.get('last_name').hasError('required') && submitted">
                Last name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input matInput placeholder="Email" required [formControlName]="'email'">
            <mat-error *ngIf="form.get('email').hasError('required') && submitted">
                E-mail is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field [floatLabel]="'auto'" style="width: 100%">
            <input matInput placeholder="Phone Number" [formControlName]="'phone_number'">
        </mat-form-field>

        <mat-form-field >
            <mat-select placeholder="Department" [formControlName]="'department'" style="width: 100%" >
                <mat-option value="nd">North Dakota</mat-option>
                <mat-option value="wy">Wyoming</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('department').hasError('required') && submitted">
                Department is required.
            </mat-error>
        </mat-form-field>


        <mat-form-field style="width: 100%">
            <mat-select placeholder="Role" [formControlName]="'role'">
                <mat-option value="root" *ngIf="user.role === 'root'">Root Administrator</mat-option>
                <mat-option value="administrator" *ngIf="user.role === 'administrator' || user.role === 'root'">Administrator</mat-option>
                <mat-option value="dispatcher">Dispatcher</mat-option>
                <mat-option value="driver">Driver</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-select placeholder="Title" [formControlName]="'title'">
                <mat-option value="Driver">Driver</mat-option>
                <mat-option value="Roustabout">Roustabout</mat-option>
                <mat-option value="Truck Push">Truck Push</mat-option>
                <mat-option value="Dispatcher">Dispatcher</mat-option>
                <mat-option value="Mechanic">Mechanic</mat-option>
                <mat-option value="Supervisor">Supervisor</mat-option>
            </mat-select>
        </mat-form-field>

        <user-avatar [user]="editUser" (save)="onAvatarSave($event)"></user-avatar>

        <div style="width: 100%" *ngIf="form.value.role === 'driver'">
            <ng-container *ngIf="form.value.keep_truckin_id === null">
                Not linked to Keep Truckin' User
                <button mat-button type="button" (click)="linkKeepTruckin()">Link</button>
            </ng-container>
            <ng-container *ngIf="form.value.keep_truckin_id !== null">
                Linked to Keep Truckin' User Id: {{ form.value.keep_truckin_id }}

                <button mat-button type="button" (click)="linkKeepTruckin()">Change</button>
                <p>
                    Name: {{ this.keepTruckinUser?.first_name }} {{ this.keepTruckinUser?.last_name}}
                </p>

            </ng-container>
        </div>


        <button mat-stroked-button [disabled]="loading" style="margin-top: 20px;">
            Save
        </button>
    </form>

</div>

<div class="back-button">
    <a mat-button routerLink="/dispatch/users">&laquo; Back to Users</a>
</div>

<div class="certs-and-trainings" *ngIf="mode === 'edit'">
    <app-user-certifications [userId]="id"></app-user-certifications>
    <app-user-trainings class="trainings" [userId]="id"> </app-user-trainings>
</div>