<button mat-button #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="dd">
    <i class="material-icons" matBadge="{{ unread }}" matBadgePosition="above after" matBadgeColor="warn">
        notifications
    </i>
</button>

<mat-menu #dd="matMenu" [overlapTrigger]="false" class="notifications-dropdown">
    <button mat-button style="width: 100%" (click)="markAllAsRead()" *ngIf="notifications.length > 0">Mark all as read</button>
    <mat-action-list>
        <ng-container *ngFor="let notification of notifications">
            <mat-list-item class="dropdown-notification" (click)="onNotificationClick(notification)" [ngClass]="{ 'unread': ! notification.data?.read }">

                <mat-icon *ngIf="notification.data.icon" matListIcon>{{ notification.data.icon }}</mat-icon>

                <img matListAvatar alt="User avatar" *ngIf="notification.data.avatar && notification.data.avatar === null" class="user-avatar" src="/assets/images/default-avatar.png" />
                <img matListAvatar alt="User avatar" *ngIf="notification.data.avatar && notification.data.avatar !== null" class="user-avatar" [src]="notification.data.avatar" />

                <h3 matLine>{{ notification.data.title }}</h3>
                <p matLine>
                    <span>{{ notification.data.message}}</span>
                </p>
                <button type="button" mat-icon-button (click)="toggleRead($event, notification)">
                    <mat-icon style="font-size: 0.75rem">brightness_1</mat-icon>
                </button>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
        <mat-list-item>
            <a [routerLink]="['/dispatch/notifications']" mat-menu-item>View All Notifications</a>
        </mat-list-item>
    </mat-action-list>
</mat-menu>