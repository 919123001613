import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrailerService } from '../../common/trailer.service';
import { TrailerTypeService } from '../../common/trailer-type.service';
import { MatSnackBar } from '@angular/material';
import { finalize } from 'rxjs/operators';
import { SearchableEndpoint } from '../search-select/search-select.component';

@Component({
    selector: 'app-edit-trailer',
    templateUrl: './edit-trailer.component.html',
    styleUrls: ['./edit-trailer.component.scss']
})
export class EditTrailerComponent implements OnInit {
    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading = true;
    public trailerTypes = [];
    public submitted = false;
    public isLoading = true;
    public defaultTypeId = null;

    constructor(private formBuilder: FormBuilder,
                private trailerService: TrailerService,
                private trailerTypeService: TrailerTypeService,
                private snackbar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            type_id: ['', Validators.required],
            year: null,
            make: null,
            model: null,
            vin: null,
            tire_size: null,
            license_plate: null,
            lienholder: null,
            notes: null,
            active: false,
            last_service_date: null,
            last_annual_inspection: null
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.loading = false;
        }
    }
    getEndpoint(): SearchableEndpoint {
        return ((search: String, page = 1) => {
            return this.trailerTypeService.find('asc', page, 25, search);
        }).bind(this);
    }

    find(id: number) {
        this.trailerService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(response => {
            this.form.patchValue({
                name: response.name,
                type_id: response.type,
                year: response.year,
                make: response.make,
                model: response.model,
                vin: response.vin,
                tire_size: response.tire_size,
                license_plate: response.license_plate,
                lienholder: response.lienholder,
                notes: response.notes,
                active: response.active,
                last_service_date: response.last_service_date !== null ? new Date(response.last_service_date) : null,
                last_annual_inspection: response.last_annual_inspection !== null ? new Date(response.last_annual_inspection) : null
            });
            this.defaultTypeId = this.form.get('type_id').value;
        });
    }

    onSubmit() {
        this.submitted = true;

        if ( ! this.form.valid) {
            return;
        }

        const payload = this.form.value;

        Object.keys(payload).forEach((key) => (payload[key] == null) && delete payload[key]);

        if (payload.last_service_date) {
            payload.last_service_date = payload.last_service_date.toLocaleDateString('en-us');
        }

        if (payload.last_annual_inspection) {
            payload.last_annual_inspection = payload.last_annual_inspection.toLocaleDateString('en-us');
        }

        if (payload.type_id !== null) {
            payload.type_id = payload.type_id.id;
        }

        this.loading = true;
        this.trailerService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe(() => {
            this.snackbar.open('Trailer saved successfully', 'close', {
                duration: 3000
            });
            this.router.navigate(['../'], {
                relativeTo: this.route
            });
        }, () => {
            this.snackbar.open('Unable to save trailer', 'close', {
                duration: 3000
            });
        });
    }

}
