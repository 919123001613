import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../common/user.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {SessionService} from '../../common/session.service';
import {KeepTruckinDialogComponent} from './keep-truckin-dialog/keep-truckin-dialog.component';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

    public form: FormGroup;
    public mode: 'new' | 'edit' = 'new';
    public id?: number = null;
    public loading = true;
    public user;
    public editUser = null;
    public submitted = false;
    public keepTruckinUser = null;

    constructor(private formBuilder: FormBuilder,
                private sessionService: SessionService,
                private userService: UserService,
                private snackbar: MatSnackBar,
                private router: Router,
                public dialog: MatDialog,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.user = this.sessionService.user;

        this.form = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', Validators.required],
            phone_number: '',
            department: 'nd',
            new_avatar: null,
            keep_truckin_id: null,
            role: ['driver', Validators.required],
            title: ['', Validators.required]
        });

        const idParam = this.route.snapshot.params['id'];

        if (idParam && idParam !== 'new') {
            this.mode = 'edit';
            this.id = +idParam;
            this.find(this.id);
        } else {
            this.editUser = {
                avatar_url: null
            };
            this.loading = false;
        }
    }

    find(id: number) {
        this.userService.findOne(id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.form.patchValue({
                email: response.email,
                first_name: response.first_name,
                last_name: response.last_name,
                department: response.department,
                role: response.role,
                title: response.title,
                keep_truckin_id: response.keep_truckin_id,
                phone_number: response.phone_number || ''
            });

            this.keepTruckinUser = response.keep_truckin_user || null;

            this.editUser = response;
        });
    }

    onSubmit() {
        this.submitted = true;

        if (!this.form.valid) {
            return;
        }

        const formValue = this.form.value;

        const payload = {
            email: formValue.email,
            first_name: formValue.first_name,
            last_name: formValue.last_name,
            role: formValue.role,
            title: formValue.title,
            new_avatar: formValue.new_avatar,
            keep_truckin_id: formValue.keep_truckin_id,
            phone_number: formValue.phone_number,
            department: formValue.department
        };

        Object.keys(payload).forEach((key) => ((payload[key] == null || payload[key] === '')) && delete payload[key]);

        this.loading = true;
        this.userService.saveOrUpdate(payload, this.id).pipe(
            finalize(() => this.loading = false)
        ).subscribe((response: any) => {
            this.snackbar.open('User saved successfully', 'Close', {
                duration: 3000
            });

            this.router.navigate(['../', response.data.id], {
                relativeTo: this.route
            });

        }, () => {
            this.snackbar.open('Unable to save user', 'Close', {
                duration: 3000
            });
        });
    }

    linkKeepTruckin() {
        const dialogRef = this.dialog.open(KeepTruckinDialogComponent, {
            width: '600px',
            data: { }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.selected && result.driver) {
                this.keepTruckinUser = result.driver;
                this.form.patchValue({
                    keep_truckin_id: result.driver.keep_truckin_id
                });
            }
        });
    }

    onAvatarSave(event) {
        this.editUser.avatar_url = event.image;

        this.form.patchValue({
            new_avatar: event.image
        });

    }
}
