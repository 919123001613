import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class JobService {

    constructor(protected apiService: ApiService) {
    }

    public getJobs(options = {}): any {
        return this.apiService.get('jobs', options);
    }

    public findOne(id, params = null) {
        let options = {};
        if (params) {
            options['params'] = params;
        }

        return this.apiService.get('jobs/' + id, options ).pipe(
            map(res => res['data'])
        );
    }

    public saveOrUpdate(payload: any, id = null) {
        if (id == null) {
            return this.apiService.post('jobs', payload);
        }


        return this.apiService.put('jobs/' + id, payload);
    }

    public finalize(id) {
        return this.apiService.put('jobs/' + id, {
            is_complete: true
        });
    }
}
