import { OnInit, Renderer2, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, takeUntil, first } from 'rxjs/operators';
import { FormControl, ControlValueAccessor } from '@angular/forms';
import { MatSelect } from '@angular/material';
var SearchSelectComponent = /** @class */ (function () {
    function SearchSelectComponent(renderer) {
        this.renderer = renderer;
        this.readOnly = null;
        this.multiple = false;
        this.shouldRefresh = null;
        this.scrollLoadThreshhold = 300;
        this.isLoadingMore = false;
        this.isLoading = true;
        this.page = 1;
        this.lastPage = 1;
        this.list = [];
        this.inputControl = new FormControl('');
        this.changed = false;
        this.itemSize = 25;
        this.extraParams = null;
        this.formatFieldName = function (listItem) { return listItem.name; };
        this.onChange = function () { };
        this.onTouch = function () { };
    }
    SearchSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.valueChangesObs = this.inputControl.valueChanges.pipe(debounceTime(600), distinctUntilChanged(), tap(function () {
            _this.isLoading = true;
            _this.page = 1;
        }), switchMap(function (search) { return _this.endpoint(search, _this.page, _this.extraParams); }));
        if (!this.readOnly) {
            this.initializeData();
        }
        // load data whenever search value changes
        this.valueChangesObs.subscribe(function (response) {
            if (!_this.readOnly) {
                if (response.meta) {
                    _this.lastPage = response.meta.last_page;
                }
                _this.writeList(response.data);
                _this.isLoading = false;
            }
        });
        if (this.shouldRefresh) {
            this.shouldRefresh.subscribe(function (extraParams) {
                _this.extraParams = extraParams;
                _this.initializeData();
            });
        }
    };
    SearchSelectComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.mainSelect.openedChange.subscribe(function (opened) {
            if (opened) {
                _this.mainSelect.panel.nativeElement.addEventListener('scroll', function (event) {
                    if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight - _this.scrollLoadThreshhold) {
                        if (!_this.isLoadingMore && !_this.isLoading) {
                            _this.loadMore();
                        }
                    }
                });
            }
        });
    };
    SearchSelectComponent.prototype.initializeData = function () {
        var _this = this;
        // load data beforehand
        this.isLoading = true;
        this.endpoint('', 1, this.extraParams).pipe(takeUntil(this.valueChangesObs)).subscribe(function (response) {
            if (response.meta) {
                _this.lastPage = response.meta.last_page;
            }
            _this.writeList(response.data);
            _this.isLoading = false;
        });
    };
    SearchSelectComponent.prototype.ngOnChanges = function (changes) {
        // If readonly is enabled then switched to disabled, initialize
        if (changes.readOnly && changes.readOnly.previousValue && !changes.readOnly.currentValue) {
            this.initializeData();
        }
    };
    SearchSelectComponent.prototype.writeList = function (list) {
        var _this = this;
        var data = list.slice();
        if (!this.multiple) {
            if (this.value && !(this.value in data)) {
                data = data.filter(function (el) { return el.id !== _this.value.id; });
                data.unshift(this.value);
            }
        }
        else {
            if (Array.isArray(this.value)) {
                var _loop_1 = function (value) {
                    data = data.filter(function (el) { return el.id !== value.id; });
                };
                for (var _i = 0, _a = this.value; _i < _a.length; _i++) {
                    var value = _a[_i];
                    _loop_1(value);
                }
                data = this.value.concat(data);
            }
        }
        this.list = data;
    };
    SearchSelectComponent.prototype.appendList = function (list) {
        this.list = this.list.concat(list);
    };
    SearchSelectComponent.prototype.writeValue = function (value) {
        if (!this.readOnly) {
            this.value = value;
            this.writeList(this.list);
            this.onChange(this.value);
        }
        else if (value !== null) {
            this.isLoading = false;
            this.value = value;
            this.writeList([]);
        }
    };
    SearchSelectComponent.prototype.loadMore = function () {
        var _this = this;
        // only load more if there is more to load
        if (this.page < this.lastPage) {
            this.isLoadingMore = true;
            this.page++;
            this.endpoint(this.inputControl.value, this.page, this.extraParams).pipe(first()).subscribe(function (response) {
                _this.appendList(response.data);
                _this.isLoadingMore = false;
            });
        }
    };
    SearchSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = function (val) {
            fn(val);
        };
        // this.onChange = fn;
    };
    SearchSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouch = fn;
    };
    SearchSelectComponent.prototype.setDisabledState = function () {
        throw new Error('not implementing');
    };
    SearchSelectComponent.prototype.makeSelection = function (event) {
        if (!this.readOnly) {
            this.changed = true;
            this.writeValue(event.value);
        }
    };
    SearchSelectComponent.prototype.clearControl = function () {
        this.writeValue(null);
        this.inputControl.patchValue(null);
        this.mainSelect.close();
    };
    SearchSelectComponent.prototype.focus = function () {
        var _this = this;
        // there has to be a better way to trigger this.
        this.onTouch();
        setTimeout(function () {
            try {
                _this.renderer.selectRootElement('#search_select_filter').focus();
            }
            catch (e) {
                // do nothing :(
            }
        }, 400);
    };
    /**
    * Handles the key down event with MatSelect.
    * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
    */
    SearchSelectComponent.prototype._handleKeydown = function (event) {
        // Prevent propagation for all alphanumeric characters in order to avoid selection issues
        if (event.key && event.key === ' ') {
            event.stopPropagation();
        }
    };
    return SearchSelectComponent;
}());
export { SearchSelectComponent };
