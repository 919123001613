import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { KeepTruckinUserService } from '../../common/keep-truckin-user.service';
import { filter, tap, takeUntil, debounceTime, flatMap, map, mergeMap } from 'rxjs/operators';
import { ReplaySubject, Subject, Observable, forkJoin } from 'rxjs';
import { TrailerType } from '../../common/trailer-type.service';
import { BolService } from '../../common/bol.service';
import { TrailerTypeService } from '../../common/trailer-type.service';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { SearchableEndpoint } from '../search-select/search-select.component';
import { AgmMap } from '@agm/core';
import { TruckBoardService } from '../../common/truck-board.service';
import { JobService } from '../../common/job.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AgmDirection } from 'agm-direction/src/modules/agm-direction.module';

@Component({
  selector: 'truck-snapshot-board',
  templateUrl: './truck-snapshot-board.component.html',
  styleUrls: ['./truck-snapshot-board.component.scss']
})
export class TruckSnapshotBoardComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('map') agmMap: AgmMap;

  private map: google.maps.Map;

  public trucks = [];

  private page = 1;
  private limit = 100;

  public form: FormGroup;

  public trailerType: FormControl = new FormControl();
  public isLoading = true;
  public googleLoading = true;
  protected _onDestroy = new Subject<void>();
  public lat = 48.144788;
  public lng = -103.727774;
  public zoom = 8;
  public defaultSymbol;
  public message: string;

  constructor(private bolService: BolService,
    private truckBoardService: TruckBoardService,
    private keepTruckinService: KeepTruckinUserService,
    private trailerTypeService: TrailerTypeService,
    private jobService: JobService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      mode: 'driverTime',
      trailerType: null,
      job: null,
    });

    this.initFromQueryParams();
  }

  ngAfterViewInit() {
    this.agmMap.mapReady.subscribe((e) => {
      this.map = e;
      this.defaultSymbol = {
        path: google.maps.SymbolPath.CIRCLE,
        strokeColor: '#BBBBFF',
        strokeWeight: 3,
        fillColor: '#9999FF',
        fillOpacity: 1.0,
        scale: 9
      };
    });

    this.form.valueChanges.subscribe((q) => {
      this.reset();
      this.pullTrucks();
    });
  }

  initFromQueryParams() {
    this.route.queryParams.subscribe((params) => {
      let obs = [];

      let mode = this.form.get('mode');
      if (params.mode) {
        mode.patchValue(params.mode, { emitEvent: false });
      }

      let job = this.form.get('job');
      if (params.job_id) {
          if (params.job_id !== (job.value ? String(job.value.id) : null)) {
              obs.push(this.jobService.findOne(params.job_id).pipe(
                  tap((response) => {
                    // this trigger valueChanges and i have no idea why
                    job.patchValue(response, { emitEvent: false });
                  })
              ));
          }
      }

      if (obs.length > 0) {
        forkJoin(obs).subscribe(() => {
          this.pullTrucks();
        });
      } else {
        this.pullTrucks();
      }
    });
  }

  displayJob(job) {
    return job.number + (job.description ? ' - ' + job.description : '');
  }

  resetDirectionsRenderers() {
    for (let truck of this.trucks) {
      if (truck.render.directionsRenderer) {
        truck.render.directionsRenderer.setMap(null);
      }

      if (truck.render.timeOverlay) {
        truck.render.timeOverlay.setMap(null);
      }
    }
  }

  reset() {
    this.resetDirectionsRenderers();

    this.page = 1;
    this.trucks = [];
  }

  refresh() {
    this.reset();
    this.pullTrucks();
  }

  pullTrucks() {
    let params: any = {};

    let jobForm = this.form.get('job');
    if (jobForm.value) {
      params.job_id = jobForm.value.id;
    }

    let trailerTypeForm = this.form.get('trailerType');
    if (trailerTypeForm.value) {
      params.trailer_type_id = trailerTypeForm.value.id;
    }

    let modeForm = this.form.get('mode');
    if (modeForm.value === 'driverTime') {
      this.truckBoardService.getDriverTime(this.page, this.limit, params).pipe(
        mergeMap((response: any) => {
          // driver duty time mode
          let driverIds = [];
          for (let truck of response.data) {
            // check for keep truckin id
            if (truck.driver.keep_truckin_id) {
              driverIds.push(truck.driver.keep_truckin_id);
            }
          }

          // get all driver times in one big chunk instead of lots of tiny requests
          if (driverIds.length > 0) {
            return this.keepTruckinService.availableTime(driverIds, 1, this.limit).pipe(
            map((drivers: any) => {
              // map drivers to trucks
              for (let truck of response.data) {
                for (let driver of drivers.users) {
                  if (truck.driver.keep_truckin_id || null === driver.user.id) {
                    // convert shift time to hours
                    driver.user.available_time.shift_hours = Math.round(driver.user.available_time.shift / 360) / 10;
                    truck.driver.keep_truckin_user = driver.user;
                    break;
                  }
                }
                // give truck information for map
                truck.render = {};
                // make truck symbol
                truck.render.symbol = this.getSymbol(truck, ((truck.driver.keep_truckin_user.available_time.shift / 3600) / 14) * 360);
              }
              return response;
              }));
            } else {
                return new Observable((subscriber) => {
                  subscriber.next(response);
                  subscriber.complete();
              });
            }
        })
      ).subscribe((response: any) => {
        this.trucks = this.trucks.concat(response.data);
        // If there's more do it again
        if (response.meta.current_page < response.meta.last_page) {
          this.page++;
          this.pullTrucks();
        }
      });
    } else {
      this.truckBoardService.getArrivalTime(this.page, this.limit, params).subscribe((response: any) => {
        // truck arrival time mode
        // loop through trucks to attach associated data
        for (let truck of response.data) {
          // give truck information for map
          truck.render = {};
          // make truck direction renderer
          if (!truck.render.directionsRenderer) {
            truck.render.directionsRenderer = new google.maps.DirectionsRenderer({
              suppressMarkers: true
            });
          }

          // truck estimated time overlay
          truck.render.timeOverlay = null;

          if (truck.destination) {
            this.getDirections(
              new google.maps.LatLng(truck.location.lat, truck.location.lng),
              new google.maps.LatLng(truck.destination.lat, truck.destination.lng)
            ).subscribe((result: any) => {
              if (result.status === google.maps.DirectionsStatus.OK) {
                let directionsRenderer = truck.render.directionsRenderer;
                directionsRenderer.setMap(this.map);
                directionsRenderer.setDirections(result);

                let duration = result.routes[0].legs[0].duration;
                truck.destination.arrival_time_formatted = duration.text;

                // make truck symbol
                // 90 minutes = green
                // 45 minuts = yellow
                // 0 minutes = red
                truck.render.symbol = this.getSymbol(truck, (duration.value / 5600) * 360);
              }
            });
          }
        }

        this.trucks = this.trucks.concat(response.data);
        // If there's more do it again
        if (response.meta.current_page < response.meta.last_page) {
          this.page++;
          this.pullTrucks();
        }
      });
    }
  }

  setCoordinates(location) {
    this.lat = location.latitude;
    this.lng = location.longitude;
  }

  displayMessage() {
    //
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getDirections(origin, destination) {
    let directionsService = new google.maps.DirectionsService();
    let request = {
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
    };

    return new Observable(subscriber => {
      directionsService.route(request, (result) => {
        subscriber.next(result);
        subscriber.complete();
      });
    });
  }

  getSymbol(truck, hue) {
    // hue should be 0 - 360
    // 0 = red, 180 = yellow, 360 = green
    let toHex = function (value) {
      if (value < 0) {
        value = 0;
      }
      let hex = value.toString(16);
      if (hex.length === 0) {
        return '00';
      } else if (hex.length === 1) {
        return '0' + hex;
      } else {
        return hex;
      }
    };

    // copy default symbol
    let symbol = { ...this.defaultSymbol };

    let sr, sg, sb, fr, fg, fb;

    if (hue === 0) {
      // no time remaining
      sr = 200;
      sg = 0;
      sb = 0;

      fr = 40;
      fg = 0;
      fb = 0;
    } else if (hue < 180) {
      // red to yellow
      sr = 255;
      sg = Math.round((hue / 180) * 255);
      sb = 0;

      fr = 200;
      fg = sg - 55;
      fb = 55;
    } else {
      // yellow to green
      sr = Math.round(((360 - hue) / 180) * 255);
      sg = 255;
      sb = 0;

      fr = sr - 55;
      fg = 200;
      fb = 55;
    }

    symbol.strokeColor = '#' + toHex(sr) + toHex(sg) + toHex(sb);
    symbol.fillColor = '#' + toHex(fr) + toHex(fg) + toHex(fb);

    if (truck.location &&
        truck.location.speed &&
        truck.location.bearing) {
      symbol.path = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
      symbol.rotation = truck.location.bearing;
      symbol.scale = 5;
    }

    return symbol;
  }

  getTrailerEndpoint(): SearchableEndpoint {
    return ((search: String, page = 1) => {
        return this.trailerTypeService.find('asc', page, 25, search);
    }).bind(this);
  }

  getJobEndpoint(): SearchableEndpoint {
    return ((search: String, page = 1) => {
        return this.jobService.getJobs({
          params: {
            page: page,
            limit: 25,
            job_number: search,
          }
      });
    }).bind(this);
  }
}
