import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-view-users-certification',
  templateUrl: './view-users-certification.component.html',
  styleUrls: ['./view-users-certification.component.scss']
})
export class ViewUsersCertificationComponent implements OnInit {

  @Input() certNotifications = [];
  @Input() total;

  @Output() remove = new EventEmitter();
  @Output() paginate = new EventEmitter();


  constructor() { }

  ngOnInit() {
  }

  pageEvent(event) {
    this.paginate.emit(event.pageIndex + 1);
  }

  removeUser(certNotification) {
    this.remove.emit(certNotification);
  }

}
