import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { KeepTruckinUserService } from '../../common/keep-truckin-user.service';
import { tap, map, mergeMap } from 'rxjs/operators';
import { Subject, Observable, forkJoin } from 'rxjs';
import { BolService } from '../../common/bol.service';
import { TrailerTypeService } from '../../common/trailer-type.service';
import { FormControl, FormBuilder } from '@angular/forms';
import { AgmMap } from '@agm/core';
import { TruckBoardService } from '../../common/truck-board.service';
import { JobService } from '../../common/job.service';
import { Router, ActivatedRoute } from '@angular/router';
var TruckSnapshotBoardComponent = /** @class */ (function () {
    function TruckSnapshotBoardComponent(bolService, truckBoardService, keepTruckinService, trailerTypeService, jobService, formBuilder, route, router) {
        this.bolService = bolService;
        this.truckBoardService = truckBoardService;
        this.keepTruckinService = keepTruckinService;
        this.trailerTypeService = trailerTypeService;
        this.jobService = jobService;
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.trucks = [];
        this.page = 1;
        this.limit = 100;
        this.trailerType = new FormControl();
        this.isLoading = true;
        this.googleLoading = true;
        this._onDestroy = new Subject();
        this.lat = 48.144788;
        this.lng = -103.727774;
        this.zoom = 8;
    }
    TruckSnapshotBoardComponent.prototype.ngOnInit = function () {
        this.form = this.formBuilder.group({
            mode: 'driverTime',
            trailerType: null,
            job: null,
        });
        this.initFromQueryParams();
    };
    TruckSnapshotBoardComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.agmMap.mapReady.subscribe(function (e) {
            _this.map = e;
            _this.defaultSymbol = {
                path: google.maps.SymbolPath.CIRCLE,
                strokeColor: '#BBBBFF',
                strokeWeight: 3,
                fillColor: '#9999FF',
                fillOpacity: 1.0,
                scale: 9
            };
        });
        this.form.valueChanges.subscribe(function (q) {
            _this.reset();
            _this.pullTrucks();
        });
    };
    TruckSnapshotBoardComponent.prototype.initFromQueryParams = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            var obs = [];
            var mode = _this.form.get('mode');
            if (params.mode) {
                mode.patchValue(params.mode, { emitEvent: false });
            }
            var job = _this.form.get('job');
            if (params.job_id) {
                if (params.job_id !== (job.value ? String(job.value.id) : null)) {
                    obs.push(_this.jobService.findOne(params.job_id).pipe(tap(function (response) {
                        // this trigger valueChanges and i have no idea why
                        job.patchValue(response, { emitEvent: false });
                    })));
                }
            }
            if (obs.length > 0) {
                forkJoin(obs).subscribe(function () {
                    _this.pullTrucks();
                });
            }
            else {
                _this.pullTrucks();
            }
        });
    };
    TruckSnapshotBoardComponent.prototype.displayJob = function (job) {
        return job.number + (job.description ? ' - ' + job.description : '');
    };
    TruckSnapshotBoardComponent.prototype.resetDirectionsRenderers = function () {
        for (var _i = 0, _a = this.trucks; _i < _a.length; _i++) {
            var truck = _a[_i];
            if (truck.render.directionsRenderer) {
                truck.render.directionsRenderer.setMap(null);
            }
            if (truck.render.timeOverlay) {
                truck.render.timeOverlay.setMap(null);
            }
        }
    };
    TruckSnapshotBoardComponent.prototype.reset = function () {
        this.resetDirectionsRenderers();
        this.page = 1;
        this.trucks = [];
    };
    TruckSnapshotBoardComponent.prototype.refresh = function () {
        this.reset();
        this.pullTrucks();
    };
    TruckSnapshotBoardComponent.prototype.pullTrucks = function () {
        var _this = this;
        var params = {};
        var jobForm = this.form.get('job');
        if (jobForm.value) {
            params.job_id = jobForm.value.id;
        }
        var trailerTypeForm = this.form.get('trailerType');
        if (trailerTypeForm.value) {
            params.trailer_type_id = trailerTypeForm.value.id;
        }
        var modeForm = this.form.get('mode');
        if (modeForm.value === 'driverTime') {
            this.truckBoardService.getDriverTime(this.page, this.limit, params).pipe(mergeMap(function (response) {
                // driver duty time mode
                var driverIds = [];
                for (var _i = 0, _a = response.data; _i < _a.length; _i++) {
                    var truck = _a[_i];
                    // check for keep truckin id
                    if (truck.driver.keep_truckin_id) {
                        driverIds.push(truck.driver.keep_truckin_id);
                    }
                }
                // get all driver times in one big chunk instead of lots of tiny requests
                if (driverIds.length > 0) {
                    return _this.keepTruckinService.availableTime(driverIds, 1, _this.limit).pipe(map(function (drivers) {
                        // map drivers to trucks
                        for (var _i = 0, _a = response.data; _i < _a.length; _i++) {
                            var truck = _a[_i];
                            for (var _b = 0, _c = drivers.users; _b < _c.length; _b++) {
                                var driver = _c[_b];
                                if (truck.driver.keep_truckin_id || null === driver.user.id) {
                                    // convert shift time to hours
                                    driver.user.available_time.shift_hours = Math.round(driver.user.available_time.shift / 360) / 10;
                                    truck.driver.keep_truckin_user = driver.user;
                                    break;
                                }
                            }
                            // give truck information for map
                            truck.render = {};
                            // make truck symbol
                            truck.render.symbol = _this.getSymbol(truck, ((truck.driver.keep_truckin_user.available_time.shift / 3600) / 14) * 360);
                        }
                        return response;
                    }));
                }
                else {
                    return new Observable(function (subscriber) {
                        subscriber.next(response);
                        subscriber.complete();
                    });
                }
            })).subscribe(function (response) {
                _this.trucks = _this.trucks.concat(response.data);
                // If there's more do it again
                if (response.meta.current_page < response.meta.last_page) {
                    _this.page++;
                    _this.pullTrucks();
                }
            });
        }
        else {
            this.truckBoardService.getArrivalTime(this.page, this.limit, params).subscribe(function (response) {
                var _loop_1 = function (truck) {
                    // give truck information for map
                    truck.render = {};
                    // make truck direction renderer
                    if (!truck.render.directionsRenderer) {
                        truck.render.directionsRenderer = new google.maps.DirectionsRenderer({
                            suppressMarkers: true
                        });
                    }
                    // truck estimated time overlay
                    truck.render.timeOverlay = null;
                    if (truck.destination) {
                        _this.getDirections(new google.maps.LatLng(truck.location.lat, truck.location.lng), new google.maps.LatLng(truck.destination.lat, truck.destination.lng)).subscribe(function (result) {
                            if (result.status === google.maps.DirectionsStatus.OK) {
                                var directionsRenderer = truck.render.directionsRenderer;
                                directionsRenderer.setMap(_this.map);
                                directionsRenderer.setDirections(result);
                                var duration = result.routes[0].legs[0].duration;
                                truck.destination.arrival_time_formatted = duration.text;
                                // make truck symbol
                                // 90 minutes = green
                                // 45 minuts = yellow
                                // 0 minutes = red
                                truck.render.symbol = _this.getSymbol(truck, (duration.value / 5600) * 360);
                            }
                        });
                    }
                };
                // truck arrival time mode
                // loop through trucks to attach associated data
                for (var _i = 0, _a = response.data; _i < _a.length; _i++) {
                    var truck = _a[_i];
                    _loop_1(truck);
                }
                _this.trucks = _this.trucks.concat(response.data);
                // If there's more do it again
                if (response.meta.current_page < response.meta.last_page) {
                    _this.page++;
                    _this.pullTrucks();
                }
            });
        }
    };
    TruckSnapshotBoardComponent.prototype.setCoordinates = function (location) {
        this.lat = location.latitude;
        this.lng = location.longitude;
    };
    TruckSnapshotBoardComponent.prototype.displayMessage = function () {
        //
    };
    TruckSnapshotBoardComponent.prototype.ngOnDestroy = function () {
        this._onDestroy.next();
        this._onDestroy.complete();
    };
    TruckSnapshotBoardComponent.prototype.getDirections = function (origin, destination) {
        var directionsService = new google.maps.DirectionsService();
        var request = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
        };
        return new Observable(function (subscriber) {
            directionsService.route(request, function (result) {
                subscriber.next(result);
                subscriber.complete();
            });
        });
    };
    TruckSnapshotBoardComponent.prototype.getSymbol = function (truck, hue) {
        // hue should be 0 - 360
        // 0 = red, 180 = yellow, 360 = green
        var toHex = function (value) {
            if (value < 0) {
                value = 0;
            }
            var hex = value.toString(16);
            if (hex.length === 0) {
                return '00';
            }
            else if (hex.length === 1) {
                return '0' + hex;
            }
            else {
                return hex;
            }
        };
        // copy default symbol
        var symbol = tslib_1.__assign({}, this.defaultSymbol);
        var sr, sg, sb, fr, fg, fb;
        if (hue === 0) {
            // no time remaining
            sr = 200;
            sg = 0;
            sb = 0;
            fr = 40;
            fg = 0;
            fb = 0;
        }
        else if (hue < 180) {
            // red to yellow
            sr = 255;
            sg = Math.round((hue / 180) * 255);
            sb = 0;
            fr = 200;
            fg = sg - 55;
            fb = 55;
        }
        else {
            // yellow to green
            sr = Math.round(((360 - hue) / 180) * 255);
            sg = 255;
            sb = 0;
            fr = sr - 55;
            fg = 200;
            fb = 55;
        }
        symbol.strokeColor = '#' + toHex(sr) + toHex(sg) + toHex(sb);
        symbol.fillColor = '#' + toHex(fr) + toHex(fg) + toHex(fb);
        if (truck.location &&
            truck.location.speed &&
            truck.location.bearing) {
            symbol.path = google.maps.SymbolPath.FORWARD_CLOSED_ARROW;
            symbol.rotation = truck.location.bearing;
            symbol.scale = 5;
        }
        return symbol;
    };
    TruckSnapshotBoardComponent.prototype.getTrailerEndpoint = function () {
        var _this = this;
        return (function (search, page) {
            if (page === void 0) { page = 1; }
            return _this.trailerTypeService.find('asc', page, 25, search);
        }).bind(this);
    };
    TruckSnapshotBoardComponent.prototype.getJobEndpoint = function () {
        var _this = this;
        return (function (search, page) {
            if (page === void 0) { page = 1; }
            return _this.jobService.getJobs({
                params: {
                    page: page,
                    limit: 25,
                    job_number: search,
                }
            });
        }).bind(this);
    };
    return TruckSnapshotBoardComponent;
}());
export { TruckSnapshotBoardComponent };
